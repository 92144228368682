import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { IFile } from '../../../types';
import api from '../../../services/api';
import styles from './style.module.scss';

interface LocationState {
  myData: IFile;
}

const FileViewer: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const [file, setFile] = useState<IFile | null>(null); // Состояние для файла
  const [filePreview, setFilePreview] = useState<string | null>(null);

  // Сохраняем файл в localStorage, если он не был сохранён
  const saveFileToLocalStorage = (file: IFile) => {
    const fileData = localStorage.getItem('files'); // Получаем все файлы из localStorage
    let files = fileData ? JSON.parse(fileData) : {}; // Если файлы есть, парсим, если нет — создаем пустой объект
    files[file.id] = file; // Сохраняем файл по ключу id
    localStorage.setItem('files', JSON.stringify(files)); // Обновляем localStorage
  };

  // Проверка и извлечение данных из localStorage
  const getFileFromLocalStorage = (id: string) => {
    const fileData = localStorage.getItem('files'); // Получаем все файлы из localStorage
    if (fileData) {
      const files = JSON.parse(fileData);
      return files[id] || null; // Если файл по id существует, возвращаем его, иначе null
    }
    return null;
  };
  useEffect(() => {
    const fetchFileData = async () => {
      if (!id) {
        console.error('ID не найден');
        return;
      }

      let fileFromStorage = getFileFromLocalStorage(id); // Проверяем файл в localStorage

      if (fileFromStorage) {
        setFile(fileFromStorage); // Если файл есть в localStorage, используем его
      } else if ((location.state as LocationState)?.myData) {
        // Если файл не найден в localStorage, пробуем загрузить его из состояния маршрута
        const fileData = (location.state as LocationState).myData;
        setFile(fileData);
        saveFileToLocalStorage(fileData); // Сохраняем в localStorage
      } else if (id && !isNaN(parseInt(id, 10))) {
        // Если файл не найден ни в localStorage, ни в состоянии маршрута, загружаем с сервера
        try {
          const result = await api.files.get(parseInt(id, 10)); // Загружаем файл с сервера по id
          setFile(result.data);
          saveFileToLocalStorage(result.data); // Сохраняем файл в localStorage для дальнейшего использования
        } catch (error) {
          console.error('Ошибка при загрузке файла с сервера:', error);
        }
      } else {
        console.error('Некорректный ID');
      }
    };

    fetchFileData();

    return () => {
      if (filePreview) {
        URL.revokeObjectURL(filePreview); // Чистим URL при размонтировании компонента
      }
    };
  }, [id, location.state]); // Перезапуск эффекта при изменении ID или состояния маршрута

  useEffect(() => {
    if (!file) return;

    const fetchPreview = async () => {
      try {
        const result = await api.files.get(file.id); // Загружаем файл с сервера
        if (file.extension === 'pdf') {
          setFilePreview(URL.createObjectURL(new Blob([result.data], { type: 'application/pdf' })));
        } else if (file.extension === 'svg') {
          setFilePreview(URL.createObjectURL(new Blob([result.data], { type: 'image/svg+xml' })));
        } else {
          setFilePreview(URL.createObjectURL(new Blob([result.data])));
        }
      } catch (error) {
        console.error('Ошибка загрузки превью файла:', error);
      }
    };

    fetchPreview();
  }, [file]); // Загрузка превью при изменении данных файла

  if (!file) {
    return <h1>Файл не найден</h1>;
  }

  return (
    <div>
      <button className='btn btn-primary' onClick={() => window.history.back()}>
        Назад
      </button>

      <h1>Название: {file.original_name}</h1>
      <p>Размер: {file.size} байт</p>
      <p>Дата загрузки: {file.date}</p>

      {file.extension === 'jpg' || file.extension === 'png' || file.extension === 'svg' ? (
        <>
          {/* Отображение изображения */}
          <img src={filePreview || ''} alt={file.original_name} style={{ maxWidth: '100%' }} />

          {/* Ссылка для скачивания */}
          <a href={filePreview || ''} download={file.original_name} style={{ display: 'block', marginTop: '10px' }}>
            Скачать изображение
          </a>
        </>
      ) : file.extension === 'pdf' ? (
        <iframe src={filePreview || ''} title="PDF Preview" style={{ width: '100%', height: '500px' }} />
      ) : (
        <a href={`/uploads/${file.name}`} download>
          Скачать файл
        </a>
      )}
    </div>
  );
};

export default FileViewer;
